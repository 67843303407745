import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import RangeSlider from "../../../../components/range-slider"
import Columns from "../../../../components/columns"
import Column from "../../../../components/column"
import Text from "../../../../components/text"
import produce from "immer"
import Stack from "../../../../components/stack"

const moods = [
  { id: "gluecklich", title: "glücklich" },
  { id: "unzufrieden", title: "unzufrieden" },
  { id: "verzweifelt", title: "verzweifelt" },
  { id: "zuversichtlich", title: "zuversichtlich" },
  { id: "entschlossen", title: "entschlossen" },
  { id: "sehnsuechtig", title: "sehnsüchtig" },
]

const MoodBarometer = ({ onChange, disabled, initialState }) => {
  const [state, setState] = useState({})

  useEffect(() => {
    if (onChange) {
      onChange({ state })
    }
  }, [state, onChange])

  const onChangeMood = ({ id, value }) => {
    const newState = produce(state, (draftState) => {
      draftState[id] = value
    })
    setState(newState)
  }

  return (
    <Stack space={8}>
      {moods.map((mood) => (
        <Columns
          alignY="center"
          collapse={[true, false, true, false]}
          key={mood.id}
        >
          <Column>
            <Text sans>{mood.title}</Text>
          </Column>
          <Column>
            <RangeSlider
              id={mood.id}
              initialValue={initialState?.[mood.id] || 1}
              disabled={disabled}
              onChange={({ value }) => {
                onChangeMood({ id: mood.id, value })
              }}
            />
          </Column>
        </Columns>
      ))}
    </Stack>
  )
}

MoodBarometer.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  initialState: PropTypes.object,
}

MoodBarometer.defaultProps = {
  disabled: false,
}

export default MoodBarometer
